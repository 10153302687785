import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './VoiceClone.module.css';
import { Mic, MicOff, Upload, AlertCircle, Trash2 } from 'lucide-react';

const SUPPORTED_LANGUAGES = [
  { code: 'hi', name: 'Hindi', template: 'नमस्ते, मैं हिंदी में बात कर रहा/रही हूं। यह मेरी आवाज़ का नमूना है।' },
  { code: 'en', name: 'English', template: 'Hello, I am speaking in English. This is a sample of my voice.' },
  { code: 'es', name: 'Spanish', template: 'Hola, estoy hablando en español. Esta es una muestra de mi voz.' },
  { code: 'fr', name: 'French', template: 'Bonjour, je parle en français. Ceci est un échantillon de ma voix.' },
  { code: 'de', name: 'German', template: 'Hallo, ich spreche auf Deutsch. Dies ist eine Probe meiner Stimme.' },
  { code: 'it', name: 'Italian', template: 'Ciao, sto parlando in italiano. Questo è un campione della mia voce.' }
];

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes
const MAX_TEXT_LENGTH = 100;

// Converts an AudioBuffer to a WAV ArrayBuffer
const encodeWAV = (audioBuffer) => {
  const sampleRate = audioBuffer.sampleRate;
  const channels = audioBuffer.numberOfChannels;
  const bufferLength = audioBuffer.length;
  const headerLength = 44;
  const totalLength = headerLength + bufferLength * channels * 2;
  const buffer = new ArrayBuffer(totalLength);
  const view = new DataView(buffer);

  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  let offset = 0;
  writeString(view, offset, 'RIFF'); offset += 4;
  view.setUint32(offset, totalLength - 8, true); offset += 4;
  writeString(view, offset, 'WAVE'); offset += 4;
  writeString(view, offset, 'fmt '); offset += 4;
  view.setUint32(offset, 16, true); offset += 4;         // PCM chunk size
  view.setUint16(offset, 1, true); offset += 2;            // PCM format (1)
  view.setUint16(offset, channels, true); offset += 2;
  view.setUint32(offset, sampleRate, true); offset += 4;
  view.setUint32(offset, sampleRate * channels * 2, true); offset += 4;
  view.setUint16(offset, channels * 2, true); offset += 2;
  view.setUint16(offset, 16, true); offset += 2;
  writeString(view, offset, 'data'); offset += 4;
  view.setUint32(offset, totalLength - offset - 4, true); offset += 4;

  // Write PCM samples interleaving channels if necessary
  if (channels === 1) {
    const channelData = audioBuffer.getChannelData(0);
    for (let i = 0; i < channelData.length; i++, offset += 2) {
      let s = Math.max(-1, Math.min(1, channelData[i]));
      view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
  } else {
    const channelData = [];
    for (let ch = 0; ch < channels; ch++) {
      channelData.push(audioBuffer.getChannelData(ch));
    }
    for (let i = 0; i < bufferLength; i++) {
      for (let ch = 0; ch < channels; ch++, offset += 2) {
        let s = Math.max(-1, Math.min(1, channelData[ch][i]));
        view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
      }
    }
  }

  return buffer;
};

// Converts a Blob (e.g., recorded in webm) to a WAV Blob
const convertBlobToWav = async (blob) => {
  const arrayBuffer = await blob.arrayBuffer();
  const audioContext = new AudioContext();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  const wavArrayBuffer = encodeWAV(audioBuffer);
  return new Blob([wavArrayBuffer], { type: 'audio/wav' });
};

const VoiceClone = () => {
  const { isAuthenticated, loading } = useContext(AuthContext);
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const [language, setLanguage] = useState('hi');
  const [audioFile, setAudioFile] = useState(null);
  const [previewAudio, setPreviewAudio] = useState(null);
  const [generatedAudio, setGeneratedAudio] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [audioSource, setAudioSource] = useState(null); // 'recorded' or 'uploaded'
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/login', { 
        state: { from: '/voice-clone' },
        replace: true
      });
    }
  }, [isAuthenticated, loading, navigate]);

  useEffect(() => {
    return () => {
      if (generatedAudio) URL.revokeObjectURL(generatedAudio);
      if (previewAudio) URL.revokeObjectURL(previewAudio);
    };
  }, []);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loading}>Loading...</div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  const clearAudio = () => {
    if (previewAudio) URL.revokeObjectURL(previewAudio);
    setAudioFile(null);
    setPreviewAudio(null);
    setAudioSource(null);
    setError('');
  };

  const startRecording = async () => {
    try {
      clearAudio();
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100,
          channelCount: 1
        } 
      });
      
      // On desktop the recorder may support 'audio/wav', but many mobile devices don't.
      // So we first try "audio/wav" and then fall back to webm if necessary.
      let options = {};
      if (MediaRecorder.isTypeSupported('audio/wav')) {
        options.mimeType = 'audio/wav';
      } else if (MediaRecorder.isTypeSupported('audio/webm;codecs=opus')) {
        options.mimeType = 'audio/webm;codecs=opus';
      } else if (MediaRecorder.isTypeSupported('audio/webm')) {
        options.mimeType = 'audio/webm';
      }
      
      mediaRecorderRef.current = new MediaRecorder(stream, options);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        // Create the raw blob from recorded chunks
        let recordedBlob = new Blob(audioChunksRef.current, { type: options.mimeType || 'audio/wav' });
        
        // If the blob isn't already in WAV format, convert it.
        if (recordedBlob.type !== 'audio/wav') {
          try {
            recordedBlob = await convertBlobToWav(recordedBlob);
          } catch (err) {
            console.error("Conversion error:", err);
            setError("Audio conversion failed. Please try again.");
            return;
          }
        }
        
        if (recordedBlob.size > MAX_FILE_SIZE) {
          setError('Recording exceeds 20MB limit. Please try again with a shorter recording.');
          return;
        }
        
        const file = new File([recordedBlob], 'recording.wav', { type: 'audio/wav' });
        setAudioFile(file);
        setPreviewAudio(URL.createObjectURL(recordedBlob));
        setAudioSource('recorded');
        
        stream.getTracks().forEach(track => track.stop());
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setError('');
    } catch (err) {
      console.error('Recording error:', err);
      setError('Error accessing microphone. Please ensure you have granted microphone permissions.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      clearAudio();
      if (file.type !== 'audio/wav') {
        setError('Please upload a WAV file');
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        setError('File size exceeds 20MB limit');
        return;
      }
      setAudioFile(file);
      setPreviewAudio(URL.createObjectURL(file));
      setAudioSource('uploaded');
    }
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (newText.length <= MAX_TEXT_LENGTH) {
      setText(newText);
      setError('');
    }
  };

  const handleLanguageChange = (e) => {
    const newLang = e.target.value;
    setLanguage(newLang);
  };

  const handleCloneVoice = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    
    if (generatedAudio) {
      URL.revokeObjectURL(generatedAudio);
      setGeneratedAudio(null);
    }

    try {
      const formData = new FormData();
      formData.append('text', text);
      formData.append('language', language);
      formData.append('speaker_file', audioFile);

      console.log('Sending request...');
      const response = await axios.post('/api/hindi-tts/clone', formData, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': localStorage.getItem('token')
        },
        onUploadProgress: (progressEvent) => {
          console.log('Upload progress:', progressEvent.loaded / progressEvent.total * 100);
        }
      });
      console.log('Response received');

      const newAudioUrl = URL.createObjectURL(response.data);
      setGeneratedAudio(newAudioUrl);

      const audioElement = document.querySelector('#generatedAudio');
      if (audioElement) {
        audioElement.load();
      }
    } catch (err) {
      console.error('Error details:', err);
      setError(err.response?.data?.error || 'Failed to clone voice');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.heroSection}>
        <Mic size={48} className={styles.heroIcon} />
        <h1 className={styles.title}>AI Voice Cloning</h1>
        <p className={styles.subtitle}>
          Clone any voice with our advanced AI technology
        </p>
      </div>

      <div className={styles.mainContent}>
        {!isAuthenticated && (
          <div className={styles.authPrompt}>
            <AlertCircle size={24} />
            <p>Please <a href="/login">login</a> to use the voice cloning feature.</p>
          </div>
        )}

        <form onSubmit={handleCloneVoice} className={!isAuthenticated ? styles.disabled : ''}>
          <div className={styles.inputGroup}>
            <label>Language</label>
            <select
              value={language}
              onChange={handleLanguageChange}
              className={styles.select}
            >
              {SUPPORTED_LANGUAGES.map(lang => (
                <option key={lang.code} value={lang.code}>
                  {lang.name}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.inputGroup}>
            <label>Text to Clone ({text.length}/{MAX_TEXT_LENGTH} characters)</label>
            <textarea
              value={text}
              onChange={handleTextChange}
              placeholder="Enter the text you want to clone in your voice..."
              required
              className={styles.textarea}
              maxLength={MAX_TEXT_LENGTH}
            />
          </div>

          <div className={styles.voiceSection}>
            <h3>Voice Sample</h3>
            <p className={styles.voiceInstructions}>
              Record or upload a WAV file (max 20MB)
            </p>

            <div className={styles.voiceControls}>
              <button
                type="button"
                onClick={isRecording ? stopRecording : startRecording}
                className={`${styles.controlButton} ${isRecording ? styles.recording : ''}`}
                disabled={!isAuthenticated}
              >
                {isRecording ? <MicOff size={20} /> : <Mic size={20} />}
                {isRecording ? 'Stop Recording' : 'Record Voice'}
              </button>

              <div className={styles.controlButtonWrapper}>
                <label className={styles.controlButton}>
                  <Upload size={20} />
                  Upload WAV
                  <input
                    type="file"
                    accept="audio/wav"
                    onChange={handleFileChange}
                    className={styles.fileInput}
                    disabled={!isAuthenticated}
                  />
                </label>
              </div>

              {previewAudio && (
                <button
                  type="button"
                  onClick={clearAudio}
                  className={`${styles.controlButton} ${styles.clearButton}`}
                >
                  <Trash2 size={20} />
                  Clear
                </button>
              )}
            </div>

            {previewAudio && (
              <div className={styles.previewSection}>
                <p className={styles.previewLabel}>
                  {audioSource === 'recorded' ? 'Recorded Voice' : 'Uploaded Voice'}:
                </p>
                <audio controls className={styles.audioPreview}>
                  <source src={previewAudio} type="audio/wav" />
                </audio>
              </div>
            )}

            <div className={styles.templateSection}>
              <p className={styles.templateLabel}>Suggested script:</p>
              <p className={styles.template}>
                {SUPPORTED_LANGUAGES.find(lang => lang.code === language)?.template}
              </p>
            </div>
          </div>

          <button 
            type="submit" 
            disabled={isLoading || !isAuthenticated || !audioFile}
            className={styles.submitButton}
          >
            {isLoading ? 'Processing...' : 'Clone Voice'}
          </button>
        </form>

        {error && <div className={styles.error}>{error}</div>}

        {generatedAudio && (
          <div className={styles.result}>
            <h3>Generated Audio</h3>
            <audio 
              controls 
              id="generatedAudio"
              className={styles.audioPlayer}
              key={generatedAudio}
            >
              <source src={generatedAudio} type="audio/wav" />
            </audio>
            <a 
              href={generatedAudio} 
              download="generated-speech.wav"
              className={styles.downloadButton}
            >
              Download Audio
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default VoiceClone; 