import React from 'react';
import Navbar from './Navbar';

function Layout({ children }) {
  return (
    <>
      <Navbar />
      {/* 
        Adding inline styling here. 
        Adjust the value ("84px") if your fixed navbar height/margin changes.
      */}
      <div style={{ paddingTop: '84px' }}>
        {children}
      </div>
    </>
  );
}

export default Layout; 