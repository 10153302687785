import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Signup from './pages/Signup';
import Login from './pages/Login';
import VoiceClone from './pages/VoiceClone';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AdminPanel from './pages/admin/AdminPanel';
import { AuthProvider, AuthContext } from './context/AuthContext';
import styles from './App.module.css';
import Layout from './components/Layout';

// Protected Route Component
const ProtectedAdminRoute = ({ children }) => {
  const { isAuthenticated, userRole, loading } = React.useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated || userRole !== 'master') {
    return <Navigate to="/login" state={{ from: '/admin' }} />;
  }

  return children;
};

function App() {
    return (
        <AuthProvider>
            <Router>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/voice-clone" element={<VoiceClone />} />
                        <Route 
                            path="/admin" 
                            element={
                                <ProtectedAdminRoute>
                                    <AdminPanel />
                                </ProtectedAdminRoute>
                            } 
                        />
                    </Routes>
                </Layout>
            </Router>
        </AuthProvider>
    );
}

export default App;