import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import styles from './Navbar.module.css';
import { Mic, Shield, Menu, X } from 'lucide-react';

const Navbar = () => {
  const { isAuthenticated, logout, userRole } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const closeMenu = () => setIsMobileMenuOpen(false);

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarContainer}>
        <Link to="/" className={styles.logo}>HINDI TTS</Link>

        <button className={styles.mobileMenuButton} onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        <ul className={`${styles.navList} ${isMobileMenuOpen ? styles.mobileActive : ''}`}>
          <li onClick={closeMenu}>
            <Link to="/" className={styles.navLink}>Home</Link>
          </li>
          <li onClick={closeMenu}>
            <Link to="/about" className={styles.navLink}>About</Link>
          </li>
          <li onClick={closeMenu}>
            <Link to="/contact" className={styles.navLink}>Contact</Link>
          </li>
          <li onClick={closeMenu}>
            <Link to="/voice-clone" className={styles.navLinkAI}>
              <Mic size={18} className={styles.icon} />
              AI Voice Clone
            </Link>
          </li>
          {isAuthenticated && userRole === 'master' && (
            <li onClick={closeMenu}>
              <Link to="/admin" className={styles.navLinkAdmin}>
                <Shield size={18} className={styles.icon} />
                Admin Panel
              </Link>
            </li>
          )}
          {!isAuthenticated ? (
            <>
              <li onClick={closeMenu}>
                <Link to="/signup" className={styles.navLink}>Sign Up</Link>
              </li>
              <li onClick={closeMenu}>
                <Link to="/login" className={styles.navLink}>Login</Link>
              </li>
            </>
          ) : (
            <li onClick={closeMenu}>
              <button onClick={handleLogout} className={styles.navLinkSignUp}>Logout</button>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;