import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import styles from './Home.module.css';
import AdUnit from '../components/AdUnit';
import { Speaker, Volume2 } from 'lucide-react';
import { Link } from 'react-router-dom';

const Home = () => {
  const { isAuthenticated, userRole } = useContext(AuthContext);
  const [text, setText] = useState('');
  const [language, setLanguage] = useState('');
  const [voice, setVoice] = useState('');
  const [languages, setLanguages] = useState([]);
  const [voices, setVoices] = useState([]);
  const [audioSrc, setAudioSrc] = useState('');
  const [error, setError] = useState('');
  const [isConverting, setIsConverting] = useState(false);
  const [audioUrls, setAudioUrls] = useState({ mp3: null, wav: null });
  const [format, setFormat] = useState('LINEAR16'); // WAV is default
  
  const isMaster = userRole === 'master';

  const generateUniqueFileName = (languageCode) => {
    const now = new Date();
    const date = now.toISOString().split('T')[0];
    const time = now.toTimeString().split(' ')[0].replace(/:/g, '-');
    return `tts_${date}_${time}_${languageCode}`;
  };

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get('/api/tts/languages');
        setLanguages(response.data);
        // Find and set Hindi (hi-IN) as default language
        const hindiLanguage = response.data.find(lang => lang.code === 'hi-IN');
        if (hindiLanguage) {
          setLanguage(hindiLanguage.code);
        } else if (response.data.length > 0) {
          setLanguage(response.data[0].code);
        }
      } catch (error) {
        console.error('Failed to fetch languages:', error);
        setError(`Failed to load languages. Error: ${error.message}`);
      }
    };

    if (isAuthenticated) {
      fetchLanguages();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const fetchVoices = async () => {
      if (!language) return;
      try {
        const response = await axios.get(`/api/tts/voices/${language}`);
        setVoices(response.data);
        if (response.data.length > 0) {
          setVoice(response.data[0].id);
        }
      } catch (error) {
        console.error('Failed to fetch voices:', error);
        setError(`Failed to load voices. Error: ${error.message}`);
      }
    };

    fetchVoices();
  }, [language]);

  const handleDownload = async () => {
    try {
      const audioUrl = audioUrls[format === 'LINEAR16' ? 'wav' : 'mp3'];
      if (!audioUrl) {
        setError('No audio available for download');
        return;
      }
      
      const response = await fetch(audioUrl);
      const blob = await response.blob();
      
      const fileName = generateUniqueFileName(language);
      const fileExtension = format === 'LINEAR16' ? 'wav' : 'mp3';
      
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}.${fileExtension}`;
      document.body.appendChild(a);
      a.click();
      
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Download error:', error);
      setError('Failed to download file');
    }
  };

  const handleConvert = async () => {
    if (!text || !voice || !language) {
      setError('Please fill in all required fields');
      return;
    }

    setIsConverting(true);
    setError('');

    try {
      const response = await axios.post('/api/tts/convert', 
        {
          text,
          voice,
          languageCode: language,
          format: format // Single format selected by user
        },
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem('token')
          }
        }
      );

      const blob = new Blob(
        [response.data], 
        { type: format === 'LINEAR16' ? 'audio/wav' : 'audio/mp3' }
      );
      
      const url = URL.createObjectURL(blob);
      setAudioUrls({
        [format === 'LINEAR16' ? 'wav' : 'mp3']: url
      });
      setAudioSrc(url);

    } catch (error) {
      console.error('Conversion error:', error);
      setError('Failed to convert text to speech');
    } finally {
      setIsConverting(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.heroSection}>
        <Speaker size={48} className={styles.heroIcon} />
        <h1 className={styles.title}>Text to Speech Converter</h1>
        <p className={styles.subtitle}>
          Convert text to natural-sounding speech in multiple languages
        </p>
      </div>

      <div className={styles.mainContent}>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              <div className={styles.languageSelector}>
                <select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className={styles.select}
                  disabled={!isAuthenticated} // Disable if not authenticated
                >
                  {languages.map((lang) => (
                    <option key={lang.code} value={lang.code}>
                      {lang.name}
                    </option>
                  ))}
                </select>
                <select
                  value={voice}
                  onChange={(e) => setVoice(e.target.value)}
                  className={styles.select}
                  disabled={voices.length === 0 || !isAuthenticated} // Disable if not authenticated
                >
                  {voices.map((v) => (
                    <option key={v.id} value={v.id}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>
              {!isMaster && (
                <div className={styles.characterLimit}>
                  {text.length}/100 characters
                </div>
              )}
            </div>

            <div className={styles.textareaContainer}>
              {isAuthenticated ? (
                <textarea
                  className={styles.textarea}
                  placeholder="Enter your text here..."
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  maxLength={isMaster ? undefined : 100}
                />
              ) : (
                <div className={styles.disabledTextarea}>
                  <textarea
                    className={styles.textarea}
                    placeholder="Please sign up or log in to use this feature..."
                    value={text}
                    onChange={(e) => setText(e.target.value)} // Prevent changing the value
                    disabled
                  />
                  <div className={styles.authPrompt}>
                    <h2>Sign Up to Start Converting</h2>
                    <p>Create a free account to use our text-to-speech converter</p>
                    <div className={styles.authButtons}>
                      <Link to="/signup" className={styles.authButton}>
                        <div className={styles['button-outer']}>
                          <div className={styles['button-inner']}>
                            <span>Sign Up</span>
                          </div>
                        </div>
                      </Link>
                      <Link to="/login" className={styles.authButton}>
                        <div className={styles['button-outer']}>
                          <div className={styles['button-inner']}>
                            <span>Login</span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={styles.cardFooter}>
              {isAuthenticated && (
                <div className={styles.controls}>
                  <select 
                    value={format}
                    onChange={(e) => setFormat(e.target.value)}
                    className={styles.select}
                  >
                    <option value="LINEAR16">WAV</option>
                    <option value="MP3">MP3</option>
                  </select>
                  <button 
                    onClick={handleConvert}
                    disabled={isConverting || !text}
                    className={styles.convertButton}
                  >
                    <div className={styles['button-outer']}>
                      <div className={styles['button-inner']}>
                        <span>{isConverting ? 'Converting...' : 'Convert'}</span>
                      </div>
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>

          {error && <div className={styles.errorMessage}>{error}</div>}

          {audioSrc && (
            <div className={styles.audioCard}>
              <audio className={styles.audioPlayer} controls src={audioSrc}></audio>
              <div className={styles.downloadButtons}>
                <button
                  onClick={handleDownload}
                  disabled={!audioSrc}
                  className={styles.downloadButton}
                >
                  <div className={styles['button-outer']}>
                    <div className={styles['button-inner']}>
                      <span>Download</span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          )}
        </div>

        <div className={styles.adSection}>
          <AdUnit />
        </div>
      </div>

      <div className={styles.featuresSection}>
        <div className={styles.featureCard}>
          <h3>Multiple Languages</h3>
          <p>Support for various languages and accents</p>
        </div>
        <div className={styles.featureCard}>
          <h3>Natural Voices</h3>
          <p>High-quality, natural-sounding speech synthesis</p>
        </div>
        <div className={styles.featureCard}>
          <h3>Easy Download</h3>
          <p>Download converted audio in MP3 format</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
