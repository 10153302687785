import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Box,
  CircularProgress,
  Alert
} from '@mui/material';
import styles from './AdminPanel.module.css';

function TabPanel({ children, value, index }) {
  return (
    <div hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function AdminPanel() {
  const [data, setData] = useState({ users: [], analytics: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    const fetchData = async () => {
      try {
        const [usersResponse, analyticsResponse] = await Promise.all([
          axios.get('/api/admin/all-users', {
            headers: { 'x-auth-token': token }
          }),
          axios.get('/api/admin/analytics', {
            headers: { 'x-auth-token': token }
          })
        ]);

        // Sort analytics by timestamp in descending order (newest first)
        const sortedAnalytics = analyticsResponse.data.sort((a, b) => 
          new Date(b.timestamp) - new Date(a.timestamp)
        );

        setData({
          users: usersResponse.data,
          analytics: sortedAnalytics
        });
        setLoading(false);
      } catch (error) {
        if (error.response?.status === 403) {
          navigate('/');
        }
        setError('You do not have permission to access this page');
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getServiceType = (entry) => {
    if (entry.voice === 'voice-clone') {
      return 'Hindi TTS (Clone)';
    } else if (entry.languageCode.startsWith('hi-')) {
      return 'Hindi TTS';
    } else {
      return 'Google TTS';
    }
  };

  if (loading) {
    return (
      <Container className={styles.container}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className={styles.container}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container className={styles.container}>
      <Typography variant="h4" component="h1" className={styles.title}>
        Admin Dashboard
      </Typography>

      <div className={styles.statsContainer}>
        <Paper className={styles.statCard}>
          <Typography variant="h6" color="primary" gutterBottom>
            Total Users
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 600 }}>
            {data.users.length}
          </Typography>
        </Paper>

        <Paper className={styles.statCard}>
          <Typography variant="h6" color="primary" gutterBottom>
            Total Requests
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 600 }}>
            {data.analytics.length}
          </Typography>
        </Paper>
      </div>

      <Box className={styles.tabsContainer}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{
            '& .MuiTab-root': {
              fontWeight: 600,
            }
          }}
        >
          <Tab label="Users" />
          <Tab label="Analytics" />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell><strong>Username</strong></TableCell>
                <TableCell><strong>Email</strong></TableCell>
                <TableCell><strong>Role</strong></TableCell>
                <TableCell><strong>Characters Used</strong></TableCell>
                <TableCell><strong>Last Updated</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell className={styles.truncatedText}>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>{user.charactersUsed}</TableCell>
                  <TableCell>{new Date(user.updatedAt).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell><strong>Username</strong></TableCell>
                <TableCell><strong>Email</strong></TableCell>
                <TableCell><strong>Service</strong></TableCell>
                <TableCell><strong>Text</strong></TableCell>
                <TableCell><strong>Language</strong></TableCell>
                <TableCell><strong>Voice</strong></TableCell>
                <TableCell><strong>Format</strong></TableCell>
                <TableCell><strong>Location</strong></TableCell>
                <TableCell><strong>Device Info</strong></TableCell>
                <TableCell><strong>Timestamp</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.analytics.map((entry) => (
                <TableRow key={entry._id}>
                  <TableCell>{entry.username}</TableCell>
                  <TableCell className={styles.truncatedText}>{entry.userEmail}</TableCell>
                  <TableCell>{getServiceType(entry)}</TableCell>
                  <TableCell className={styles.truncatedText}>{entry.text.substring(0, 50)}...</TableCell>
                  <TableCell>{entry.languageCode}</TableCell>
                  <TableCell>{entry.voice}</TableCell>
                  <TableCell>{entry.format}</TableCell>
                  <TableCell className={styles.truncatedText}>{`${entry.city}, ${entry.country}`}</TableCell>
                  <TableCell className={styles.truncatedText}>{`${entry.browser} - ${entry.os}`}</TableCell>
                  <TableCell>{new Date(entry.timestamp).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </Container>
  );
}

export default AdminPanel;