import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import styles from './Login.module.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useContext(AuthContext);

  // Get the path from location state, or default to home page
  const from = location.state?.from || '/';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      await login(email, password);
      // Navigate to the page that requested login
      navigate(from, { replace: true });
    } catch (error) {
      setError(error.response?.data?.message || 'An error occurred during login');
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Login</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className={styles.input}
          disabled={isLoading}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className={styles.input}
          disabled={isLoading}
        />
        <button 
          type="submit" 
          className={styles.button}
          disabled={isLoading}
        >
          <div className={styles['button-outer']}>
            <div className={styles['button-inner']}>
              <span>{isLoading ? 'Logging in...' : 'Login'}</span>
            </div>
          </div>
        </button>
      </form>
      {error && <p className={styles.error}>{error}</p>}
      {/* Optional: Show where user will be redirected */}
      <p className={styles.redirectInfo}>
        You will be redirected to: {from === '/' ? 'Home' : from.replace('/', '')}
      </p>
    </div>
  );
};

export default Login;